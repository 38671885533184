import { httpShared, throwOnAxiosError } from './http'

export async function settingsGetAll() {
  try {
    const res = await httpShared.get('/v1/settings')
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve settings')
  }
}

export async function settingsUpdatePartial(delta) {
  try {
    await httpShared.patch('/v1/settings', delta)
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update settings')
  }
}

export async function settingsOverwriteKey(key, data) {
  try {
    await httpShared.put(`/v1/settings/${key}`, data)
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update settings')
  }
}

export async function settingsGetMaterialPickerSetting() {
  try {
    const res = await httpShared.get('/v1/settings')
    const data = res.data
    return {
      ...(data.curriculumId && {
        curriculumId: data.curriculumId,
      }),
      ...Object.keys(data.preferredMaterial || {}).reduce((acc, course) => {
        acc[parseInt(course)] = data.preferredMaterial[course]
        return acc
      }, {}),
    }
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve settings')
  }
}

export async function settingsSetCurriculum(curriculumId) {
  try {
    await httpShared.patch('/v1/settings', { curriculumId })
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update settings')
  }
}

export async function settingsSetMaterialPickerSetting(courseId, materialId) {
  try {
    await httpShared.patch('/v1/settings', {
      preferredMaterial: { [courseId]: materialId },
    })
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update settings')
  }
}

/* picks = [{curriculumId, courseId, materialId}, {...}] */
export async function settingsSetRecentlyPicked(picks) {
  try {
    await httpShared.put('/v1/settings/recentMaterials', picks)
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update settings')
  }
}

export async function settingsGetRecentlyPicked() {
  try {
    const res = await httpShared.get('/v1/settings')
    return res.data.recentMaterials || []
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve recently picked materials')
  }
}

export async function settingsSetRecentSubject(curriculumId, subjectId) {
  try {
    await httpShared.patch('/v1/settings', {
      recentSubject: { [curriculumId]: subjectId },
    })
  } catch (err) {
    throwOnAxiosError(err, 'Failed to update preferred subject')
  }
}

export async function settingsGetRecentSubject() {
  try {
    const res = await httpShared.get('/v1/settings')
    return res.data.recentSubject
  } catch (err) {
    throwOnAxiosError(err, 'Failed to get preferred subject')
  }
}
