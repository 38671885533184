import {
  examGetById,
  examGetByUUId,
  examSave,
  examCreate,
  examGetByIdStudent,
  type ExamForSaving,
} from '@/services/exam'
import { getTotalPointsPerGrade } from '@/utils/getTotalGradePointsPerAbility'
import { Difficulty, ExamType, ExamStatus } from '@/constants'
import { getDifficulty } from '@/utils/difficulty'
import type { subQuestionV2 } from '@/utils/questionConverter'
import type { DifficultyPoint } from 'gauss-matrix'

async function getExamById(id: number, anonymous = false): Promise<Exam> {
  const exam = await examGetById(id, anonymous)
  return mapExamFromBackend(exam)
}
async function getExamByUUId(uuid: string): Promise<Exam> {
  const exam = await examGetByUUId(uuid)
  return mapExamFromBackend(exam)
}

type StudentExam = Exam

async function getStudentExamById(
  id: number,
  anonymous = false
): Promise<StudentExam> {
  const exam = await examGetByIdStudent(id, anonymous)
  return mapExamFromBackend(exam)
}

async function saveExam(exam: Exam): Promise<Exam> {
  let partsForSaving = null
  if (exam?.parts) {
    partsForSaving = exam.parts.map((part) => {
      const { questions, ...rest } = part
      return {
        ...rest,
        questionVersionIds: questions.map((q) => q.primaryId),
      }
    })
  }
  const examForSaving: ExamForSaving = {
    name: exam.name,
    type: exam.type,
    group: exam.group,
    material: exam.material,
    settings: exam.settings,
    status: exam.status,
    ...(partsForSaving && { parts: partsForSaving }),
  }
  const savedExam = await examSave(exam.id, examForSaving)
  return mapExamFromBackend(savedExam)
}

async function createExam(exam: Exam, copiedFrom: number) {
  const examForSaving = {
    name: exam.name,
    type: exam.type,
    group: exam.group,
    material: exam.material,
    category: exam.category,
    copiedFrom,
    settings: exam.settings,
    status: ExamStatus.CLOSED,
    parts: exam.parts.map((part) => {
      const { questions, ...rest } = part
      return {
        ...rest,
        questionVersionIds: questions.map((q) => q.primaryId),
      }
    }),
  }
  const examCreated = await examCreate(examForSaving)

  return mapExamFromBackend(examCreated)
}

type AbilityMap = {
  [key: string]: string
}

function mapQuestion(q: any, abilityMap: AbilityMap, bookId: number): Question {
  if (bookId) {
    injectChapter(q, bookId)
  }
  const primaryId = q.questionVersionId
  const displayId = q.id + '-' + q.version
  const criterias = mapCriterias(
    q.questionVersionId,
    abilityMap,
    q.content,
    q.context
  )
  const availablePoints = mapAvailablePoints(criterias)
  const difficulty = getQuestionDifficulty(criterias)
  return { ...q, primaryId, displayId, criterias, availablePoints, difficulty }
}

type LegacyExamPartSettings = ExamPartSettings & {
  digital?: {
    heading: string
    answerArea: string
  }
  print?: {
    heading: string
    answerArea: string
  }
}

export function fixLegacyExamPartSettings(
  partSettings: LegacyExamPartSettings
): ExamPartSettings {
  if (partSettings.digital === undefined) {
    return partSettings
  }
  const newPartSettings = JSON.parse(JSON.stringify(partSettings))
  const digitalHeading = partSettings.digital.heading // it can be null also
  const digitalAnswerArea = partSettings.digital.answerArea // will be undefined next time after saving the exam

  newPartSettings.heading = digitalHeading || partSettings.heading || ''
  newPartSettings.answerArea = digitalAnswerArea || partSettings.answerArea
  delete newPartSettings.digital
  delete newPartSettings.print

  return newPartSettings
}

function mapExamFromBackend(exam: any): Promise<Exam> {
  const abilityMap = getAbilityMapFromExam(exam)
  exam.parts.forEach((part: any) => {
    part.questions = part.questions.map((q: any) => {
      return mapQuestion(q, abilityMap, exam.material.id)
    })
    part.settings = fixLegacyExamPartSettings(part.settings)
  })
  // exam.settings.basic.type is moved to exam.type
  exam.type = exam.type || exam.settings.basic.type || ExamType.Exam
  return exam
}

function getAbilityMapFromExam(exam: Exam): AbilityMap {
  return exam.course.abilities.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: item.name,
    }
  }, {})
}

type Criterium = {
  criteriaIndex: number
  subchapterId: number
  subchapterName: string
  questionPrimaryId: number
  subQuestionId: number
  pointType: string
  pointIndex: number
  ability: string
  abilityKey: string
  points: number
}

function mapCriterias(
  primaryId: number,
  abilityMap: AbilityMap,
  content: subQuestionV2[],
  context: QuestionContext
): Criterium[] {
  const pointTypes = getPointTypes()
  // V2 content assumption
  return content.flatMap((sq) =>
    (sq.data.criterias || []).map((criteria, index) => ({
      criteriaIndex: index,
      subchapterId: context.subchapter?.id ?? 0, // O in case when question is not in the same book as the exam
      subchapterName: context.subchapter?.name ?? '',
      questionPrimaryId: primaryId,
      subQuestionId: sq.id,
      pointType: criteria.level,
      pointIndex: pointTypes.findIndex((pt) => pt.key === criteria.level),
      ability: abilityMap[criteria.ability],
      abilityKey: criteria.ability,
      points: 1,
    }))
  )
}

function mapAvailablePoints(criterias: Criterium[]): DifficultyPoint {
  return criterias.reduce(
    (total, criteria) => {
      total[criteria.pointIndex]++
      return total
    },
    [0, 0, 0]
  )
}

function injectChapter(q: Question, bookId: number) {
  const location = q.context.usedIn?.find(
    (location) => location.book.id === Number(bookId)
  )
  if (location) {
    q.context.chapter = location.chapter
    q.context.subchapter = location.subchapter
    q.context.course = location.course
  }
}

function getPointTypes() {
  return [
    { key: 'e', name: Difficulty.EASY },
    { key: 'c', name: Difficulty.MEDIUM },
    { key: 'a', name: Difficulty.DIFFICULT },
  ]
}

const getQuestionDifficulty = (criterias: Criterium[]) => {
  const pointTypes = getPointTypes().map((level) => level.key)
  const totalPointsPerGradeResult = getTotalPointsPerGrade(
    pointTypes,
    criterias
  )
  const { weightedMeanValue } = getDifficulty(totalPointsPerGradeResult)
  return weightedMeanValue
}

export default {
  getExamById,
  getExamByUUId,
  getStudentExamById,
  saveExam,
  createExam,
  mapQuestion,
}
