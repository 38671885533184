import { toRef, computed } from 'vue'

import { useGroupStore } from '@/stores/group'

export const useGroupStudentFormatAndSort = () => {
  const groupStore = useGroupStore()
  const group = toRef(groupStore, 'group')

  const nameDisplayOrder = computed(
    () => group.value.settings.studentSortBy.nameDisplayOrder
  )
  const userListOrder = computed(
    () => group.value.settings.studentSortBy.userListOrder
  )

  const getDisplayName = (student: Partial<Student>) => {
    const firstName = student.firstName
    const lastName = student.lastName

    if (nameDisplayOrder.value === 'firstName') {
      return `${firstName} ${lastName}`
    }
    return `${lastName} ${firstName}`
  }

  const sortStudents = (array: Partial<Student>[]) => {
    const copyArr = JSON.parse(JSON.stringify(array))
    // multisort by first name and last name
    copyArr.sort((a: any, b: any) => {
      if (userListOrder.value === 'firstName') {
        const primaryComparison = a.firstName.localeCompare(b.firstName)
        if (primaryComparison !== 0) return primaryComparison
        // primary is equal, try secondary
        return a.lastName.localeCompare(b.lastName)
      } else {
        const primaryComparison = a.lastName.localeCompare(b.lastName)
        if (primaryComparison !== 0) return primaryComparison
        // primary is equal, try secondary
        return a.firstName.localeCompare(b.firstName)
      }
    })
    return copyArr
  }

  return {
    getDisplayName,
    sortStudents,
    userListOrder,
  }
}
