import { API } from './API'
import { API2 } from './API2'
import { APIv2 } from './APIv2'
import { KM } from './KM'
import { getDelTypes } from '@/km/utils/deltypes.js'
import { formatKMToISO } from '@/km/utils/formatKMToISO'
import { useUserStore } from '@/stores/user'
import { useExamStore } from '@/stores/exam'
import { useKMTokenStore } from '@/km/stores/token'
import {
  saveKMExam,
  mapKMExamForPrint,
  getConnectedFormageMatris,
} from './KMSaveExam.js'
import { generateDefaultLimitarr } from '../utils/generateLimitarr'
import { questionSearch } from '@/km/services/question'
import { getTotalPointsPerGrade } from '@/utils/getTotalGradePointsPerAbility'
import { getDifficulty } from '@/utils/difficulty'
import {
  Difficulty,
  QuestionPointsMode,
  EXAM_TYPES,
  ExamType,
  ExamAnswerArea,
} from '@/constants'
import { useMemoize } from '@vueuse/core'
import { convertV1toV2limits } from 'gauss-matrix'

export function getUppgifterIdsInOrder(strUppgiftIds) {
  return strUppgiftIds
    .split('#')
    .join(',')
    .split(',')
    .filter((x) => x !== '')
}

function questionBelongsToPart(uppgifterPerPart, questionId) {
  return uppgifterPerPart.indexOf(
    uppgifterPerPart.find((a) => a.split(',').includes(questionId))
  )
}

export function questionsChangedHash(parts) {
  return parts
    .flatMap((p) => p.questions)
    .map((q) => q.id)
    .sort()
    .join(',')
}

function mapTestType(id) {
  const res = Array.from(EXAM_TYPES.keys()).find((key) => {
    return EXAM_TYPES.get(key).kmId === id
  })
  return res || ExamType.Exam
}

export async function mapToNewGaussExam(params, defaultTitle) {
  const course = await getKMCourse(params.courseValue)
  const courseSettings = getCourseSettingsFromCourseObject(course)
  const teacherId = useUserStore().user.userId
  let klass
  if (Number(params.classValue)) {
    klass = await getKMClass(Number(params.classValue), teacherId)
  }
  const metadata = {}
  return {
    name: params.examName || defaultTitle,
    language: 'sv',
    course: {
      id: Number(course.courseid),
      name: course.coursename,
      subjectId: course.subjectid,
      curriculumId: 1,
      language: 'sv',
      settings: courseSettings,
    },
    group: {
      id: Number(params.classValue),
      ...(klass && { name: klass.classname }),
    },
    material: {
      id: course.courseid + ':' + params.preferredBook,
      name: params.preferredBook,
      type: 'KMBOOK',
    },
    parts: [],
    settings: {
      ...useExamStore().getDefaultExamSettings({
        pointsMode: courseSettings.pointsMode,
      }),
    },
    type: mapTestType(Number(params.examTypeValue)),
    metadata,
  }
}

function mapAnswerOverrides(overrides, parts) {
  const allQuestions = parts
    .flatMap((p) => p.questions)
    .reduce((acc, q) => {
      acc[q.id] = q
      return acc
    }, {})
  const answerOverrides = {}
  Object.keys(overrides).forEach((id) => {
    const question = allQuestions[id]
    if (!question) {
      return
    }
    const questionOverrides = overrides[id]
    const maxOverrideCount = Math.min(
      questionOverrides.length,
      question.content.length
    )
    for (let i = 0; i < maxOverrideCount; i++) {
      if (questionOverrides[i] !== '0') {
        const subQuestionId = question.content[i].id
        answerOverrides[id] = {
          ...answerOverrides[id],
          [subQuestionId]: questionOverrides[i],
        }
      }
    }
  })
  return answerOverrides
}

async function mapToGaussExam(id, test) {
  const orderUppgifter = getUppgifterIdsInOrder(test.uppgifter)
  const testParts = [[], [], []]

  const uppgifterPerPart = test.uppgifter.split('#')
  const course = await getKMCourse(test.courseid)
  const courseSettings = getCourseSettingsFromCourseObject(course)
  const abilityMap = await getAbilityMapForCourse(course)
  // always load the exam using a safe choice of book
  const bookKey = await getSafeBookKey(
    Number(test.courseid),
    Number(test.classid),
    test.bookkey
  )

  const bookFile = `${course.coursecode}_${test.courseid}_${bookKey}_l.js`
  const { subchapterMap, repetitionMap } = await getBookMaps(bookFile)

  const examCourse = await getCourse(test.courseid)

  const questionMap = test.uppgiftcontent.reduce((acc, uppgift) => {
    acc.set(
      parseInt(uppgift.id),
      mapQuestion(
        uppgift,
        subchapterMap,
        repetitionMap,
        abilityMap,
        course,
        courseSettings.calculator
      )
    )
    return acc
  }, new Map())

  orderUppgifter.forEach((id) => {
    // const pointsPerAbility = []
    // this.course.formagor.map((y, i) => {
    //   const obj = {}
    //   obj.abilityLabel = y[0]
    //   obj.abilityShortLabel = y[1]
    //   obj.description = y[2]
    //   obj.id = i
    //   obj.pointsPerLevel = [
    //     uppgift['e' + i],
    //     uppgift['c' + i],
    //     uppgift['a' + i],
    //   ]
    //   pointsPerAbility.push(obj)
    // })
    // uppgift.pointsPerAbility = pointsPerAbility
    const partIndex = questionBelongsToPart(uppgifterPerPart, id)
    testParts[partIndex].push(parseInt(id))
  })

  // remove empty parts after the last part with questions
  const lastPartWithQuestions = testParts.reduce(
    (acc, p, i) => (p.length > 0 ? i : acc),
    null
  )
  testParts.splice(lastPartWithQuestions + 1)
  const titleoverride = JSON.parse(test.titleoverridearr)

  const coverSheetSettings = {
    enabled: titleoverride[0][2]?.enabled,
    title: titleoverride[0][2]?.title,
    instructions: titleoverride[0][2]?.instructions,
    removeLogo: titleoverride[0][2]?.removeLogo,
  }

  const parts = testParts.map((sourcePart, index) => {
    const part = {}
    part.settings = {
      calculatorAllowed: true,
      answerArea: ExamAnswerArea.SEPARATE,
    }
    if (titleoverride[index + 1] && titleoverride[index + 1][0] !== null) {
      const heading = titleoverride[index + 1][0]
      const regex = /<span[^>]+id=.auto./
      if (regex.test(heading)) {
        // interpreted as auto generated heading
        part.settings.heading = undefined
      } else {
        // the old km returns <p>&nbsp;&nbsp;&nbsp;</p> as heading if no student instructions
        const stripHtmlTags = heading.replace(/<[^>]*>/g, '')
        const pureHeading = stripHtmlTags.replace(/^(&nbsp;|\s)*$/g, '')
        if (pureHeading === '') {
          // interpreted as no student instructions
          part.settings.heading = ''
        } else {
          // retain km heading as it is
          part.settings.heading = heading
        }
      }
    }
    const partIndex = index + 1
    part.deltypeValue = Number(JSON.parse(test.deltypes)[partIndex])
    const deltypeInfo = getDelTypes(part.deltypeValue)
    part.settings.calculatorAllowed = deltypeInfo.calculator || false
    // if we have inline answers on print we need to have inline answers on digital
    const digitalInline = deltypeInfo.kortsvar || deltypeInfo.digital
    part.settings.answerArea = digitalInline
      ? ExamAnswerArea.INLINE
      : ExamAnswerArea.SEPARATE
    part.questions = sourcePart.map((id) => {
      return questionMap.get(id)
    })
    return part
  })

  const advsettings = test.advsettings.split('|')
  const answerOverrides = mapAnswerOverrides(
    JSON.parse(test.ansoverridearr),
    parts
  )
  let limitarr
  try {
    limitarr = JSON.parse(test.limitarr)
  } catch (error) {
    console.error('Error parsing limitarr', error)
    const course = await getKMCourse(examCourse.id)
    const allQuestions = parts.flatMap((p) => p.questions)
    const connectFormagor = course.connectformagor
    const connectedFormageMatris = getConnectedFormageMatris(
      connectFormagor,
      allQuestions
    )
    limitarr = generateDefaultLimitarr(
      course.subjectid,
      connectFormagor,
      connectedFormageMatris
    )
  }
  const connectedAbilities = limitarr.connectformagor
  const limits = convertV1toV2limits(limitarr.limits)
  const teacherId = useUserStore().user.userId
  let klass = { classname: '' }
  if (Number(test.classid)) {
    klass = await getKMClass(test.classid, teacherId)
  }

  return {
    id,
    name: test.namn,
    language: 'sv',
    type: mapTestType(Number(test.testtype)),
    settings: {
      blockStudentAccess: (test.classblock || '').split(',').map(Number),
      basic: {
        pointsMode: mapPointsMode(advsettings[8]) || courseSettings.pointsMode,
        date: formatKMToISO(test.datum),
        duration: Number(test.timeest),
      },
      coverSheet: coverSheetSettings,
      limits,
      connectedAbilities,
      answerOverrides,
    },
    metadata: {
      limitarr,
      titleoverridearr: JSON.parse(test.titleoverridearr),
      testdate: test.testdate,
      orgid: test.orgid,
      hash: questionsChangedHash(parts),
      examcode: test.examcode,
    },
    createdAt: new Date().toISOString,
    updatedAt: new Date().toISOString,
    material: {
      id: test.courseid + ':' + test.bookkey,
      name: test.bookkey,
      type: 'KMBOOK',
    },
    course: examCourse,
    group: {
      id: Number(test.classid),
      name: klass.classname,
    },
    parts,
  }
}

function parseBookId(bookId) {
  return {
    courseId: parseInt(bookId.split(':')[0]),
    bookKey: bookId.split(':')[1],
  }
}

async function getExamById(id) {
  // Get uppgiftcontent
  const result = await API.getExtraInfoExam(id)
  const test = result.data.sqldata[0]
  return await mapToGaussExam(id, test)
}

function mapSpecialTask(kmSpecialTask) {
  switch (kmSpecialTask) {
    case '0':
      return undefined
    case '1':
      return 'oral'
    // FIXME
  }
}

function mapContext(
  q,
  subchapterMap,
  repetitionMap,
  course,
  courseHasCalculator
) {
  const special = mapSpecialTask(q.specialTask)
  const momentid = q.vmomid || q.momentid
  const subchapterEntry = subchapterMap[momentid]
    ? subchapterMap[momentid]
    : repetitionMap[momentid]
      ? repetitionMap[momentid]
      : null
  if (!subchapterEntry) {
    console.warn('No subchapter found for momentid', q.vmomid, q.momentid)
  }
  const chapter = subchapterEntry?.chapter
  const studentAccess =
    Number(q.blockthis) === 0 &&
    ('studentopen' in q ? q.studentopen !== '0' : q.mb === 0)

  const context = {
    usedInExams: mapQuestionUsedInExams(q),
    ...(chapter && { chapter }),
    ...(subchapterEntry && {
      subchapter: { id: subchapterEntry.id, name: subchapterEntry.name },
    }),
    autocorrect: Number(q.autocorr) !== 0,
    ...(special && { special }),
    studentAccess,
    course: {
      id: course.courseid,
      name: course.coursename,
    },
  }
  if (courseHasCalculator) {
    context.calculator = (q.dv || q.digitalaverktyg || '0') !== '0'
  }

  if (q.kommentar && q.kommentar.charAt(0) === '@') {
    context.origin = q.kommentar.split(' ')[0].replace('@', '').split('\n')
  }

  return context
}

function mapQuestionUsedInExams(q) {
  return (q.inTests || []).map((test) => {
    return {
      id: test.testId,
      name: test.testName,
      type: mapTestType(Number(test.testType)),
      date: test.date,
      deleted: test.testStatus,
      group: {
        name: test.className,
        archived: test.classArchived,
      },
    }
  })
}

function mapSolution(solution) {
  if (solution.indexOf('.') !== -1) {
    // if solution begins with "v2" then use assets.km.se as the base url
    const baseUrl =
      solution.indexOf('v2') === 0
        ? 'https://assets.km.se'
        : import.meta.env.VITE_KM_URL

    return {
      type: 'image',
      id: solution,
      url: baseUrl + '/solutions/' + solution,
    }
  } else {
    return {
      type: 'youtube',
      id: solution,
      url: 'https://www.youtube.com/watch?v' + solution,
    }
  }
}
function mapSolutions(solutionSource) {
  return (solutionSource || '')
    .split('|')
    .filter((s) => s)
    .map((s) => mapSolution(s))
}

function mapAvailablePoints(criterias) {
  return criterias.reduce(
    (total, criteria) => {
      total[criteria.pointIndex]++
      return total
    },
    [0, 0, 0]
  )
}

function mapCriterias(momentId, abilityMap, subchapterMap, content) {
  const pointTypes = getPointTypes()
  const criterias = content
    .filter((sq) => sq.data.criterias)
    .flatMap((sq) => sq.data.criterias)
  return criterias.map((criteria) => ({
    subchapterId: momentId,
    subchapterName: subchapterMap[momentId]?.name ?? 'Annat',
    pointType: criteria.level,
    pointIndex: pointTypes.findIndex((pt) => pt.key === criteria.level),
    ability: abilityMap[criteria.ability],
    abilityKey: criteria.ability,
    points: 1,
  }))
}

const getKMClass = useMemoize(async (classId, teacherId) => {
  try {
    return await APIv2.getClass(classId, teacherId)
  } catch (error) {
    // FIXME
    console.error(error)
    return {}
  }
})

const getKMCourse = useMemoize(async (courseId) => {
  const response = await API.getCourse(courseId)
  const course = response.data.sqldata[0]
  course.courseid = Number(course.courseid)
  course.subjectid = Number(course.subjectid)
  course.books = JSON.parse(course.books)
  course.connectformagor = JSON.parse(course.connectformagor)
  course.coursesettings = JSON.parse(course.coursesettings)
  course.formagor = JSON.parse(course.formagor)
  return Object.freeze(course)
})

async function getCourse(courseId) {
  const course = await getKMCourse(courseId)
  const courseSettings = getCourseSettingsFromCourseObject(course)
  return {
    id: Number(course.courseid),
    name: course.coursename,
    code: course.coursecode,
    language: 'sv',
    curriculumId: 1,
    subjectId: course.subjectid,
    settings: courseSettings,
    abilities: course.formagor.map((f) => ({
      id: f[1],
      name: f[0],
      description: f[2],
    })),
  }
}

function getAbilityMapForCourse(course) {
  const formagor = course.formagor
  return formagor.reduce((obj, item) => {
    return {
      ...obj,
      [item[1]]: item[0],
    }
  }, {})
}

const getBook = useMemoize(async (bookFile) => {
  return await KM.getBook(bookFile)
})

function mapMomentName(name, type) {
  switch (type) {
    case 'R':
      return name + ' (repetition)'
    case 'F':
      return name + ' (fördjupning)'
    default:
      return name
  }
}

async function getHierarchyFromMaterial(bookId) {
  const { courseId, bookKey } = parseBookId(bookId)
  const course = await getKMCourse(courseId)
  const bookFile = `${course.coursecode}_${courseId}_${bookKey}_l.js`
  const result = await getBook(bookFile)

  const vCourse = result.data.momtable[0].vcourse

  const moments = result.data.momtable
    .map((m) => ({
      id: m.vmomid,
      name: m.vmoment,
    }))
    .reduce((acc, curr) => {
      return { ...acc, [curr.id]: curr.name }
    }, {})

  let books
  if (vCourse !== 0 && course.coursetype === 'v') {
    // multiple years

    const years = new Set()
    for (const item of result.data.momtable) {
      years.add(item.vcourse)
    }
    const yearsArr = [...years]

    let yearConverter = (y) => y
    // New courses are Gy25 and have "Nivå" instead of "Åk"
    if (course.courseid < 280) {
      yearConverter = (y) => {
        return `Åk ${y}`
      }
    }

    books = yearsArr.map((y) => {
      return {
        name: yearConverter(y),
        type: 'BOOK',
        hierarchy: result.data.cctable
          .filter((cc) => {
            return cc[1] === y
          })
          .map((cc) => ({
            id: cc[0],
            name: cc[2],
            items: cc[3]
              // Some moments listed in cctable are actually deleted
              .filter((m) => moments[m[0]])
              .map((m) => ({
                id: m[0],
                name: mapMomentName(moments[m[0]], m[1]),
              })),
          })),
      }
    })
  } else {
    books = [
      {
        name: '',
        type: 'BOOK',
        hierarchy: result.data.cctable.map((cc) => ({
          id: cc[0],
          name: cc[2],
          items: cc[3].map((m) => ({ id: m[0], name: moments[m[0]] })),
        })),
      },
    ]
  }

  if (result.data.rmomtable && result.data.rmomtable.length > 0) {
    const rmoments = result.data.rmomtable.map((m) => ({
      id: m.vmomid,
      name: m.vmoment,
    }))
    books[0].hierarchy.push({
      id: 'repetition',
      name: 'Repetition',
      items: rmoments,
    })
  }

  if (books.length > 1) {
    return { type: 'BOOK_SERIES', books }
  }
  return { type: 'BOOK', hierarchy: books[0].hierarchy }
}

async function getBookMaps(bookFile) {
  const result = await getBook(bookFile)
  const subchapterMap = result.data.momtable
    .map((m) => ({
      id: m.vmomid,
      name: m.vmoment,
      chapterName: m.vcc,
      chapterId: m.vccid,
    }))
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: {
          id: curr.id,
          name: curr.name,
          chapter: {
            id: curr.chapterId,
            name: curr.chapterName,
          },
        },
      }
    }, {})
  const repetitionMap = result.data.rmomtable
    .map((m) => ({
      id: m.vmomid,
      name: m.vmoment,
    }))
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: {
          id: curr.id,
          name: curr.name,
          chapter: {
            id: 'repetition',
            name: 'Repetition',
          },
        },
      }
    }, {})
  return { subchapterMap, repetitionMap }
}

function mapSolutionStats(solstat) {
  const solutionStats = JSON.parse(solstat)
  return {
    degreeOfResolution: {
      e: solutionStats[0],
      c: solutionStats[1],
      a: solutionStats[2],
      perPoint: solutionStats[8].map((p) => Number(p)),
    },
    averageResolution: {
      e: solutionStats[3],
      c: solutionStats[4],
      a: solutionStats[5],
    },
    sample: { answers: solutionStats[6], classes: solutionStats[7] },
  }
}

function isValidUrl(url) {
  // Regular expression to check if the string is a valid URL
  const urlRegex = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/

  return urlRegex.test(url)
}
function mapLiteratureToHtml(html) {
  try {
    const obj = JSON.parse(html)

    if (obj.audio) {
      // add audio tag to the top of the page with content under
      return {
        type: 'audio',
        html: `<audio controls src="${obj.audio}"></audio>` + obj.text,
      }
    }
    // if obj.text is a a entirely a URL then return is as a link
    if (isValidUrl(obj.text)) {
      return {
        type: 'link',
        html: obj.text,
      }
    }
    return {
      type: 'text',
      html: obj.text,
    }
  } catch (error) {
    return { type: 'html', html }
  }
}

async function mapLiterature(attachments) {
  // FIXME O(n)

  // only get info for unique attachments
  const uniqueLiteratureIds = [...new Set(attachments.map((r) => r.id))]

  const litteratureInfo = await Promise.all(
    uniqueLiteratureIds.map((rId) => API.getLiteratureInfo(rId))
  )
  return litteratureInfo
    .map((info) => info.data.sqldata[0])
    .filter((info) => info)
    .map((info) => {
      const htmlObj = mapLiteratureToHtml(info.html)
      return {
        id: info.id,
        title: info.fulltitle,
        author: info.author,
        type: htmlObj.type,
        html: htmlObj.html,
      }
    })
}

async function getAttachmentsContent(attachments) {
  return await mapLiterature(attachments)
}

const getQuestionDifficulty = (criterias) => {
  const pointTypes = getPointTypes().map((level) => level.key)
  const totalPointsPerGradeResult = getTotalPointsPerGrade(
    pointTypes,
    criterias
  )
  const { weightedMeanValue } = getDifficulty(totalPointsPerGradeResult)
  return weightedMeanValue
}

function mapQuestion(
  q,
  subchapterMap,
  repetitionMap,
  abilityMap,
  course,
  courseHasCalculator
) {
  const { user } = useUserStore()
  const content = JSON.parse(q.htmlarr)
  if (!content) {
    console.warn('No content for question', q.id || q.version || q.uppgiftid)
    return { id: parseInt(q.id || q.version || q.uppgiftid) }
  }
  const momentid = q.vmomid || q.momentid
  const criterias = mapCriterias(momentid, abilityMap, subchapterMap, content)
  const id = parseInt(q.id || q.version || q.uppgiftid)
  return {
    id,
    displayId: String(id),
    primaryId: id,
    content,
    difficulty: getQuestionDifficulty(criterias),
    context: mapContext(
      q,
      subchapterMap,
      repetitionMap,
      course,
      courseHasCalculator
    ),
    settings: {
      shortFormQuestion: Number(q.kortsvar) !== 0,
    },
    source: {
      creator: user.userId === Number(q.tid || q.teacherid) ? 'own' : 'teachiq',
      title: q.kommentar,
    },
    solutionStatistics: q.solstat ? mapSolutionStats(q.solstat) : undefined,
    solutions: mapSolutions(q.solutionsource),
    // criterias to be revised
    availablePoints: mapAvailablePoints(criterias),
    criterias,
    attachments: q.litids
      .split(',')
      .filter((id) => id !== '0')
      .map((litid) => ({ id: litid })),
    metadata: {
      subQuestionFingerprint: content.map(
        (subquestion) => subquestion.type !== 'informationBlock'
      ),
    },
  }
}

function getPointSummary(criterias) {
  let hasError = false
  const levels = {
    a: 0,
    c: 0,
    e: 0,
  }

  if (!criterias || criterias.length === 0) {
    return ''
  }

  criterias.forEach((item) => {
    if (!item.level) {
      hasError = true
    } else {
      const character = item.level.toLowerCase()
      levels[character]++
    }
  })

  const pointSummary = hasError
    ? 'NaN'
    : `(${levels.e}/${levels.c}/${levels.a})`

  return pointSummary
}

// If a question is updated in the built in question editor we may need to
// recalculate the criterias
async function refreshQuestionCriterias(bookId, q) {
  const { courseId } = parseBookId(bookId)
  const course = await getKMCourse(courseId)
  const abilityMap = await getAbilityMapForCourse(course)
  const subchapterMap = await getSubchapterMapForCourse(bookId)
  const momentid = q.vmomid || q.momentid
  q.criterias = mapCriterias(momentid, abilityMap, subchapterMap, q.content)
  q.content.forEach((sq) => {
    sq.point = getPointSummary(sq.data.criterias)
  })
}

function mapNodesToMomentIds(nodes, subchapterMap, repetitionMap) {
  let repetition = false
  const momentIds = nodes.flatMap((n) => {
    if (n.type === 'allRepetition') {
      repetition = true
      return Object.values(repetitionMap).map((m) => m.id)
    } else if (n.type === 'chapter') {
      return Object.values(subchapterMap)
        .filter((m) => m.chapter.id === n.id)
        .map((m) => m.id)
    } else if (n.type === 'subchapter') {
      return [n.id]
    } else if (n.type === 'repetition') {
      repetition = true
      return [n.id]
    }
  })
  return { ids: momentIds, repetition }
}

async function promiseQuestions(bookId, nodes, params) {
  const { courseId, bookKey } = parseBookId(bookId)
  const course = await getKMCourse(courseId)
  const bookFile = `${course.coursecode}_${courseId}_${bookKey}_l.js`
  const { subchapterMap, repetitionMap } = await getBookMaps(bookFile)
  const { ids, repetition } = mapNodesToMomentIds(
    nodes,
    subchapterMap,
    repetitionMap
  )

  const results = await questionSearch(
    course.coursetype === 'u' && ids.length > 0 ? ids : [],
    course.coursetype === 'v' && ids.length > 0 ? ids : [],
    repetition ? null : courseId,
    bookKey,
    params
  )

  return {
    facets: results.facets,
    totalCount: results.metadata.count,
    promises: results.questions.map((question, index) => {
      const promiseObject = {}
      promiseObject.id = question.id
      if (params.query === String(question.id)) {
        // Make sure keys are not reused if we are searching for a specific question
        // because it is likely that the question have changed
        promiseObject.key =
          question.id * 1000 + Math.round(Math.random() * 1000)
      } else {
        promiseObject.key = question.id
      }
      promiseObject.promiseRetriever = () => {
        if (!promiseObject.promise) {
          promiseObject.promise = getQuestion(
            question.id,
            null,
            bookId,
            question.vmomid
          ).catch((e) => {
            promiseObject.error = e
            return null
          })
        }
        return promiseObject.promise
      }
      if (index <= 5) {
        promiseObject.promiseRetriever()
      }
      return promiseObject
    }),
  }
}

const getSubchapterMapForCourse = useMemoize(async (bookId) => {
  const { courseId, bookKey } = parseBookId(bookId)
  const course = await getKMCourse(courseId)
  const bookFile = `${course.coursecode}_${courseId}_${bookKey}_l.js`
  const { subchapterMap } = await getBookMaps(bookFile)
  return subchapterMap
})

async function getQuestion(id, _, bookId, vmomid) {
  const { courseId, bookKey } = parseBookId(bookId)
  const course = await getKMCourse(courseId)
  const courseSettings = getCourseSettingsFromCourseObject(course)
  const abilityMap = await getAbilityMapForCourse(course)
  const bookFile = `${course.coursecode}_${courseId}_${bookKey}_l.js`
  const { subchapterMap, repetitionMap } = await getBookMaps(bookFile)
  const res = await API.getUppgift(id)
  const rawQuestion = res.data.sqldata[0]
  rawQuestion.vmomid = vmomid
  const final = mapQuestion(
    rawQuestion,
    subchapterMap,
    repetitionMap,
    abilityMap,
    course,
    courseSettings.calculator
  )

  return final
}

async function getQuestionByPrimaryId(id, bookId) {
  return await getQuestion(id, null, bookId)
}

function getPointTypes() {
  return [
    { key: 'e', name: Difficulty.EASY },
    { key: 'c', name: Difficulty.MEDIUM },
    { key: 'a', name: Difficulty.DIFFICULT },
  ]
}

async function getAbilities(bookId) {
  const { courseId } = parseBookId(bookId)
  const course = await getKMCourse(courseId)

  return course.formagor.map((f) => ({
    id: f[1],
    name: f[0],
    description: f[2],
  }))
}
async function getTeacherInfo(userId) {
  return await API2.getTeacherInfo(userId)
}

async function getClassesAndSharedClasses(userId, subjectId) {
  const classes = await API.getClassesAndSharedClasses(userId, subjectId)
  return classes.data.map((c) => ({
    id: c.classId,
    name: c.className,
    courseDisplayName: c.courseCode,
    sharedClass: c.sharedClass,
  }))
}

async function getMyCourses() {
  const { user } = useUserStore()
  const teacherInfo = await getTeacherInfo(user.userId)
  return teacherInfo.courses.map((c) => ({
    id: c.id,
    name: c.name,
    code: c.code,
    teacherAccess: c.teacherAccess,
    preferredBook: c.id + ':' + c.preferredBook,
    books: Object.keys(c.books).map((bookKey) => ({
      id: c.id + ':' + bookKey,
      name: c.books[bookKey],
    })),
  }))
}

async function getMyCurricula() {
  return [
    {
      id: 1,
      name: 'Svensk (Lgr22/Gy11)',
    },
  ]
}

async function getCurriculumById() {
  const courses = await getMyCourses()
  return {
    id: 1,
    name: 'Svensk (Lgr22/Gy11)',
    shortName: 'sl',

    subjects: [
      {
        id: 1,
        name: '',
        shortName: null,
        courses: courses.map((course) => {
          return {
            id: course.id,
            name: course.name,
            language: 'sv',
            preferredBook: course.preferredBook,
            teacherAccess: course.teacherAccess,
            books: course.books.map((book) => ({
              id: book.id,
              name: book.name === 'Ingen/Annan' ? 'Enligt KM' : book.name,
            })),
          }
        }),
      },
    ],
  }
}

async function getCourseSettings(courseId) {
  const course = await getKMCourse(courseId)
  return getCourseSettingsFromCourseObject(course)
}

function getCourseSettingsFromCourseObject(course) {
  const settings = course.coursesettings
  return {
    calculator: settings.calcred !== 0,
    gradeThreshold: 'default', // TODO: Check if this is correct?
    pointsMode: mapPointsMode(settings.showpointsnotmatrix),
    displayCriteriasAsMatrixThreshold: settings.matrixtreshold,
    skipBlankAbilityRows: Number(settings.specialmatrix) === 1,
  }
}

function mapPointsMode(kmPointsMode) {
  switch (Number(kmPointsMode)) {
    case 0:
    case 3:
      return QuestionPointsMode.POINTS_DIFFICULTY
    case 1:
      return QuestionPointsMode.POINTS_TOTAL
    default:
      return QuestionPointsMode.POINTS_TOTAL
  }
}

async function checkExamQuestions(courseId, questions) {
  return await APIv2.checkQuestions(courseId, questions)
}

async function saveExam(exam) {
  const { user } = useUserStore()
  const teacherInfo = await getTeacherInfo(user.userId)
  const course = await getKMCourse(exam.course.id)
  return await saveKMExam(exam, course, teacherInfo)
}

async function createExam(exam, copiedFrom) {
  const { user } = useUserStore()
  const teacherInfo = await getTeacherInfo(user.userId)
  const course = await getKMCourse(exam.course.id)
  const { examId, examcode, swappedQuestions } = await saveKMExam(
    exam,
    course,
    teacherInfo,
    copiedFrom
  )
  if (swappedQuestions && swappedQuestions.length > 0) {
    return await getExamById(examId)
  }
  exam.id = examId
  if (examcode) {
    exam.metadata ||= {}
    exam.metadata.examcode = examcode
  }
  return exam
}

async function getMaterialPickerSettings() {
  return {}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function setMaterialPickerSettings(curriculumId, courseId, materialId) {
  // NOOP
}

async function getRecentlyPickedMaterial() {
  return []
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function setRecentlyPickedMaterial(materials) {
  // NOOP
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getRecentSubject() {
  // NOOP
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function setRecentSubject(curriculumId, subjectId) {
  // NOOP
}

const kmReportTypes = {
  category: 1,
  marking: 2,
  spelling: 3,
  rights: 4,
  duplicate: 5,
  other: 9,
}

async function reportQuestion(kmId, _versionId, courseId, { type, comment }) {
  const { user } = useUserStore()

  await API.reportQuestion(kmId, comment, {
    courseId,
    errortype: kmReportTypes[type],
    userid: user.userId,
  })
  return true
}

function bookSorting(_bookId) {
  // FIXME Do we need this?
  return []
}

async function sendFeedback(rating, comment) {
  const { user } = useUserStore()
  const teacherInfo = await getTeacherInfo(user.userId)
  const message = `Betyg: ${rating}\nKommentar: ${comment}`
  await KM.sendEmailFeedback({
    version: teacherInfo.teacher.version + user.userId,
    message,
    teacherid: user.userId,
  })
}

async function getPrintPostBody(gaussExam) {
  const { user } = useUserStore()
  const course = await getKMCourse(gaussExam.course.id)
  const teacherInfo = await getTeacherInfo(user.userId)

  const exam = await mapKMExamForPrint(gaussExam, course, teacherInfo)

  return {
    test: JSON.stringify(exam),
    teacherId: String(user.userId),
    course: JSON.stringify(course),
    session: JSON.stringify({ teacherid: user.userId }),
    titleoverridearr: exam.titleoverridearr,
    ansoverridearr: exam.ansoverridearr,
    allq: JSON.stringify(exam.uppgiftcontent),
    formagearr: JSON.stringify(course.formagor),
    coursesettings: JSON.stringify(course.coursesettings),
    coursename: course.coursename,
    deltypes: JSON.stringify(exam.deltypes),
    digitalt: '',
    verq: '',
    showpointsnotmatrix: String(exam.showpointsnotmatrix),
    studentAnswers: '',
    studentName: '',
  }
}

async function getExamPreviewURL(gaussExam) {
  const tokenStore = useKMTokenStore()

  if (!gaussExam.metadata.examcode) {
    const exam = await getExamById(gaussExam.id)
    gaussExam.metadata.examcode = exam.metadata.examcode
  }

  const previewURL = new URL(`${import.meta.env.VITE_EXAM_BASE_URL}/kmt`)
  previewURL.searchParams.append('examcode', gaussExam.metadata.examcode)
  previewURL.searchParams.append('token', tokenStore.token || '')
  previewURL.searchParams.append('firstname', 'Test')
  previewURL.searchParams.append('lastname', 'Person')
  previewURL.searchParams.append('demo', '1')
  return previewURL.toString()
}

function getQuestionEditorUrl(questionId, materialId = '', forceNew = true) {
  const tokenStore = useKMTokenStore()
  const baseUrl = import.meta.env.VITE_KM_EDITOR_URL
  let url = `${baseUrl}/?token=${tokenStore.token}`
  if (questionId !== 0) {
    url += `&questionid=${questionId}`
  }
  if (questionId !== 0 && forceNew) {
    url += '&new=1'
  }
  if (materialId) {
    const { courseId } = parseBookId(materialId)
    url += '&courseid=' + courseId
  }
  return url
}

async function uploadSolution(solution, questionId) {
  try {
    const { user } = useUserStore()

    const payload = {
      soltype: solution.type,
      soldata: solution.url,
      savesoluid: questionId,
      userid: user.userId,
    }

    return await API.saveNewSolution(payload)
  } catch (e) {
    throw new Error('Failed to upload solution', e.message)
  }
}

async function getDefaultLimits(exam) {
  const course = await getKMCourse(exam.course.id)
  const allQuestions = exam.parts.flatMap((p) => p.questions)
  const connectFormagor = course.connectformagor
  const connectedFormageMatris = getConnectedFormageMatris(
    connectFormagor,
    allQuestions
  )
  const limitarr = generateDefaultLimitarr(
    course.subjectid,
    connectFormagor,
    connectedFormageMatris
  )
  return {
    limits: convertV1toV2limits(limitarr.limits),
    connectedAbilities: limitarr.connectformagor,
  }
}

async function getQuestionForEditor(id, content) {
  const res = await API.getQuestion(id)
  const rawQuestion = res.data.sqldata[0]
  rawQuestion.htmlarr = JSON.stringify(content)
  return rawQuestion
}
async function isQuestionUsedInExams(id) {
  const res = await API.isQuestionUsedInExams(id)
  return res.data.hasAssessments || res.data.hasSubmissions
}

async function getSafeBookKey(
  courseId,
  classId = undefined,
  examBook = undefined
) {
  const teacherId = useUserStore().user.userId
  const teacherInfo = await getTeacherInfo(teacherId)
  const teacherCourseInfo = teacherInfo.courses.find((c) => c.id === courseId)
  // Lets retrieve a list of books for the course
  let courseBooks = teacherCourseInfo?.books
  if (!courseBooks) {
    const { books } = await getKMCourse(courseId)
    courseBooks = books
  }
  // Class takes precedence over teacher preferred book
  if (classId) {
    const klass = await getKMClass(classId, teacherId)
    // Not all exams in the class are in the same course so check if the book is valid
    if (courseBooks[klass.book]) {
      return klass.book
    }
  }
  if (courseBooks[teacherCourseInfo.preferredBook]) {
    return teacherCourseInfo.preferredBook
  }
  // It is unlikely that we will end up here but if we do then pick the
  // exams book or the last book in courseBooks
  if (examBook && courseBooks[examBook]) {
    return examBook
  }

  return courseBooks[courseBooks.length - 1]
}

async function getPreferredBook(courseId, classId) {
  const teacherId = useUserStore().user.userId
  if (classId) {
    const klass = await APIv2.getClass(classId, teacherId)
    if (Number(klass.courseid) === Number(courseId)) {
      return { id: courseId + ':' + klass.book, name: klass.book }
    }
  }
  const teacherInfo = await getTeacherInfo(teacherId)
  const teacherCourseInfo = teacherInfo.courses.find(
    (c) => c.id === Number(courseId)
  )

  if (!teacherCourseInfo) {
    return undefined
  }

  return {
    id: courseId + ':' + teacherCourseInfo.preferredBook,
    name: teacherCourseInfo.preferredBook,
  }
}

async function hierachySummaryEnabledForCourse(courseId) {
  const course = await getKMCourse(courseId)
  return course.coursetype === 'u'
}

export default {
  getExamById,
  // getMyCourses,
  getMyCurricula,
  getCurriculumById,
  getClassesAndSharedClasses,
  // getMyBooksByCourseId,
  getCourse,
  getCourseSettings,
  checkExamQuestions,
  saveExam,
  createExam,
  getQuestion,
  getQuestionByPrimaryId,
  refreshQuestionCriterias,
  //  getQuestionByVersionId,
  // findQuestions,
  promiseQuestions,
  reportQuestion,
  getHierarchyFromMaterial,
  // getQuestions,
  getAbilities,
  getPointTypes,
  getAttachmentsContent,
  getMaterialPickerSettings,
  setMaterialPickerSettings,
  getRecentlyPickedMaterial,
  setRecentlyPickedMaterial,
  getRecentSubject,
  setRecentSubject,
  bookSorting,
  sendFeedback,
  uploadSolution,
  getPrintPostBody,
  getExamPreviewURL,
  getQuestionEditorUrl,
  getDefaultLimits,
  getQuestionForEditor,
  isQuestionUsedInExams,
  getPreferredBook,
  hierachySummaryEnabledForCourse,
}
