import type { answerArea } from '@/utils/questionConverter'
import { AnswerAreaCode, getAnswerAreaTypeTranslationKey } from '@/constants'

export const getAnswerAreaTypes = () =>
  ({
    [AnswerAreaCode.DEFAULT]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: false,
        size: 'auto',
      },
      img: '00-answer-area-standard.png',
    },
    [AnswerAreaCode.ANSWER_STANDARD]: {
      settings: {
        answerRow: 'small',
        background: 'none',
        outline: false,
        size: 'auto',
      },
      img: '01-answer-line-standard.png',
    },
    [AnswerAreaCode.ANSWER_LONG]: {
      settings: {
        answerRow: 'large',
        background: 'none',
        outline: false,
        size: 'auto',
      },
      img: '04-answer-line-long.png',
    },
    [AnswerAreaCode.MOTIVATION_NORMAL]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: true,
        size: 'medium',
      },
      img: '02-motivation-box-normal.png',
    },
    [AnswerAreaCode.MOTIVATION_SMALL]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: true,
        size: 'small',
      },
      img: '05-motivation-box-small.png',
    },
    [AnswerAreaCode.MOTIVATION_LARGE]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: true,
        size: 'high',
      },
      img: '06-motivation-box-large.png',
    },
    [AnswerAreaCode.MOTIVATION_ANSWER]: {
      settings: {
        answerRow: 'small',
        background: 'none',
        outline: true,
        size: 'medium',
      },
      img: '03-motivation-box-answer.png',
    },
    [AnswerAreaCode.MOTIVATION_GRID]: {
      settings: {
        answerRow: 'none',
        background: 'squared',
        outline: true,
        size: 'medium',
      },
      img: '17-motivation-box-grid.png',
    },
    [AnswerAreaCode.MOTIVATION_GRID_LARGE]: {
      settings: {
        answerRow: 'none',
        background: 'squared',
        outline: true,
        size: 'high',
      },
      img: '18-motivation-box-grid-large.png',
    },
    [AnswerAreaCode.MOTIVATION_DOTTED]: {
      settings: {
        answerRow: 'none',
        background: 'dotted',
        outline: true,
        size: 'medium',
      },
      img: '07-motivation-box-dotted.png',
    },
    [AnswerAreaCode.MOTIVATION_DOTTED_LARGE]: {
      settings: {
        answerRow: 'none',
        background: 'dotted',
        outline: true,
        size: 'high',
      },
      img: '08-motivation-box-dotted-large.png',
    },
    [AnswerAreaCode.EXTRA_SPACE_3]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: false,
        size: 'small',
      },
      img: '09-extra-space-3cm.png',
    },
    [AnswerAreaCode.EXTRA_SPACE_6]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: false,
        size: 'medium',
      },
      img: '10-extra-space-6cm.png',
    },
    [AnswerAreaCode.EXTRA_SPACE_12]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: false,
        size: 'high',
      },
      img: '12-extra-space-12cm.png',
    },
    [AnswerAreaCode.NONE]: {
      settings: {
        answerRow: 'none',
        background: 'none',
        outline: false,
        size: 'auto',
      },
      img: '99-answer-area-none.png',
    },
  }) as Record<string, { settings: answerArea; img: string }>

export type AnswerAreaOption = {
  id: AnswerAreaCode
  name: string
  img: string
}

export const getAnswerAreaOptions = () => {
  return Object.entries(getAnswerAreaTypes()).map(([key, value]) => ({
    id: key,
    img: getImgUrl(value.img),
    name: getAnswerAreaTypeTranslationKey(key as AnswerAreaCode),
  })) as AnswerAreaOption[]
}

const getImgUrl = (name: string) => {
  return new URL(
    `../assets/pictures/answer-area-types/${name}`,
    import.meta.url
  ).href
}
