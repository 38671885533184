import { trackRouter } from 'vue-gtag-next'
import { createWebHistory, createRouter, isNavigationFailure } from 'vue-router'
import {
  useRequiresTeacherAuthRule,
  useRequiresStudentAuthRule,
  useAlreadyHasAuthRule,
} from './hooks/routes/auth'
import { useSignupCode } from './hooks/routes/signup'
import { routes as adminRoutes } from './admin/router.js'
import { useTokenStore } from '@/stores/token'
import { useUserStore } from '@/stores/user'
import { useSessionCacheStore } from '@/stores/sessionCache'
import { kmRoutes } from './km/router'
import Bugsnag from '@bugsnag/js'
import { AssessmentMode } from '@/constants'

const AssessmentEntryLayoutView = () =>
  import('./views/assessment/AssessmentEntryLayoutView.vue')
const SignView = () => import('./views/SignView.vue')
const SignEntrypoint = () => import('./views/components/SignEntrypoint.vue')
const LoginEmail = () => import('./views/login/LoginEmail.vue')
const ChangePassword = () => import('./views/login/ChangePassword.vue')
const RequestPassword = () => import('./views/login/RequestPassword.vue')
const Logout = () => import('./views/login/Logout.vue')
const SignupEmail = () => import('./views/signup/SignupEmail.vue')
const SignupCode = () => import('./views/signup/SignupCode.vue')
const SignupCodeLong = () => import('./views/signup/SignupCodeLong.vue')
const SignupCodeVerified = () => import('./views/signup/SignupCodeVerified.vue')
const SignupCodeInvalid = () => import('./views/signup/SignupCodeInvalid.vue')
const SignupSuggestedSchools = () =>
  import('./views/signup/SignupSuggestedSchools.vue')
const SignupPickSchool = () => import('./views/signup/SignupPickSchool.vue')
const SignupCreateSchool = () => import('./views/signup/SignupCreateSchool.vue')
const SignupPersonalDetails = () =>
  import('./views/signup/SignupPersonalDetails.vue')
const SignupSubjectNotAvailable = () =>
  import('./views/signup/SignupSubjectNotAvailable.vue')
const SignupStudent = () => import('./views/signup/SignupStudent.vue')
const MainView = () => import('./views/MainView.vue')
const Home = () => import('./views/Home.vue')
const Settings = () => import('./views/Settings.vue')
const MyExams = () => import('./views/MyExams.vue')
const ExamPrintView = () => import('./views/ExamPrintView.vue')
const QuestionBankView = () => import('./views/QuestionBankView.vue')
const Books = () => import('./views/Books.vue')
const ExamEditorView = () => import('./views/ExamEditorView.vue')
const BookEdit = () => import('./views/BookEdit.vue')
const SSOAuthReceiver = () => import('./views/SSOAuthReceiver.vue')
const PageNotFound = () => import('./views/PageNotFound.vue')
const NotVerified = () => import('./views/login/NotVerified.vue')
const Sandbox = () => import('./views/Sandbox.vue')
const TestEntrypoint = () => import('./views/TestEntrypoint.vue')
const ExamLibraryContent = () =>
  import('./views/exam-library/ExamLibraryContent.vue')
const ExamLibraryTable = () =>
  import('./views/exam-library/ExamLibraryTable.vue')
const ExamLibraryColleagues = () =>
  import('./views/exam-library/ExamLibraryColleagues.vue')
const RouterView = () => import('./views/RouterViewSlot.vue')
const MyExamOverview = () => import('./views/exams/MyExamOverview.vue')
const GroupsView = () => import('./views/GroupsView.vue')
const GroupView = () => import('./views/group/GroupView.vue')
const GroupStudentsTable = () => import('./views/group/GroupStudentsTable.vue')
const GroupExamsTable = () => import('./views/group/GroupExamsTable.vue')
const GroupPractice = () => import('./views/group/practice/GroupPractice.vue')
const StudentView = () => import('./views/group/student/StudentView.vue')
const StudentOverview = () =>
  import('./views/group/student/StudentOverview.vue')
const StudentExams = () => import('./views/group/student/StudentExams.vue')
const StudentActivity = () =>
  import('./views/group/student/StudentActivity.vue')
const StudentSettings = () =>
  import('./views/group/student/StudentSettings.vue')
const QuestionView = () => import('./views/question/QuestionView.vue')
const QuestionBank = () => import('./views/question-bank/QuestionBank.vue')
const ExamReview = () => import('./views/exam/ExamReview.vue')
const ExamOverviewTab = () => import('./views/exam/ExamOverviewTab.vue')
const ExamSettingsTab = () => import('./views/exam/ExamSettingsTab.vue')
const ExamDigitalTab = () => import('./views/exam/ExamDigitalTab.vue')
const ExamPrintTab = () => import('./views/exam/ExamPrintTab.vue')
const ExamSummaryTable = () =>
  import('./views/exam/components/summary-table/ExamSummaryTable.vue')
const ExamSummaryAttachments = () =>
  import('./views/exam/components/ExamSummaryAttachments.vue')
const MyExamEntry = () => import('./views/exams/MyExamEntry.vue')
const MyExamAssessments = () =>
  import('./views/exams/assessment/MyExamAssessments.vue')
const MyExamSettings = () => import('./views/exams/MyExamSettings.vue')
const MyExamMonitoring = () => import('./views/exams/MyExamMonitoring.vue')
const MyExamPublish = () => import('./views/exams/publish/MyExamPublish.vue')

const studentRoutes = [
  {
    path: '/student-assessment/:assessmentId',
    name: 'student-assessment',
    component: AssessmentEntryLayoutView,
    ...useRequiresStudentAuthRule(),
    props: { mode: AssessmentMode.STUDENT },
  },
  {
    path: '/student-feedback/:assessmentId',
    name: 'student-feedback',
    component: AssessmentEntryLayoutView,
    ...useRequiresStudentAuthRule(),
    props: { mode: AssessmentMode.FEEDBACK },
  },
]

const defaultRoutes = [
  ...adminRoutes,
  ...studentRoutes,
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/sandbox',
    name: 'sandbox',
    component: Sandbox,
  },
  // { // Keep this for future use
  //   path: '/upload',
  //   name: 'upload',
  //   component: Upload,
  // },
  {
    path: '/',
    name: 'authenticated',
    component: MainView,
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: { isHomeView: true },
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: 'my-exams/',
        name: 'my-exams-entry',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'my-exams',
            component: MyExams,
          },
          {
            path: ':examId/',
            name: 'my-exam-entry',
            component: MyExamEntry,
            children: [
              {
                path: '',
                name: 'my-exam-overview',
                component: MyExamOverview,
              },
              {
                path: 'assessments',
                name: 'my-exam-assessments',
                component: MyExamAssessments,
              },
              {
                path: 'settings',
                name: 'my-exam-settings',
                component: MyExamSettings,
              },
              {
                path: 'monitoring',
                name: 'my-exam-monitoring',
                component: MyExamMonitoring,
              },
              {
                path: 'publish',
                name: 'my-exam-publish',
                component: MyExamPublish,
              },
            ],
          },
        ],
      },
      {
        path: 'questions/',
        name: 'questions-entry',
        component: RouterView,
        props: { keepAlive: true },
        meta: {
          breadcrumbs: 'questionBank',
          isMainQuestionBank: true,
        },
        children: [
          {
            path: '',
            name: 'questions',
            component: QuestionBankView,
          },
          {
            path: ':questionId',
            name: 'qbank-question',
            props: true,
            component: QuestionView,
            meta: { isQuestionView: true },
          },
        ],
      },
      {
        path: 'books',
        name: 'books',
        component: Books,
        meta: {
          breadcrumbs: 'books',
          isBooksView: true,
        },
      },
      {
        path: 'exam-library',
        name: 'exam-library-entry',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'exam-library',
            component: ExamLibraryContent,
          },
          {
            path: 'colleague-exams',
            name: 'colleague-exams',
            component: ExamLibraryColleagues,
            meta: { isColleagueExams: true, breadcrumbs: 'colleague' },
          },
          {
            path: 'national-exams',
            name: 'national-exams',
            component: ExamLibraryTable,
            meta: { isNationalExams: true, breadcrumbs: 'national' },
          },
          {
            path: 'course-exams',
            name: 'course-exams',
            component: ExamLibraryTable,
            meta: { isCourseExams: true, breadcrumbs: 'course' },
          },
          {
            path: 'teachiq-exams',
            name: 'teachiq-exams',
            component: ExamLibraryTable,
            meta: { isTeachiqExams: true, breadcrumbs: 'km' },
          },
          {
            path: 'all-exams',
            name: 'all-exams',
            component: ExamLibraryTable,
            meta: { isAllExams: true, breadcrumbs: 'all' },
          },
        ],
      },
      {
        path: 'groups/',
        name: 'groups-entry',
        component: RouterView,
        children: [
          { path: '', name: 'groups', component: GroupsView },
          {
            path: ':groupId/',
            name: 'group',
            component: GroupView,
            meta: { isGroupView: true },
            props: true, // groupId is sent as prop
            children: [
              {
                path: '',
                name: 'group-students',
                component: GroupStudentsTable,
              },
              {
                path: 'exams',
                name: 'group-exams',
                component: GroupExamsTable,
              },
              {
                path: 'practice',
                name: 'group-practice',
                component: GroupPractice,
              },
            ],
          },
          {
            path: ':groupId/student/:studentId/',
            name: 'group-student-entry',
            component: StudentView,
            meta: { isStudentView: true },
            children: [
              {
                path: '',
                name: 'group-student-overview',
                component: StudentOverview,
              },
              {
                path: 'exams',
                name: 'group-student-exams',
                component: StudentExams,
              },
              {
                path: 'activity',
                name: 'group-student-activity',
                component: StudentActivity,
              },
              {
                path: 'settings',
                name: 'group-student-settings',
                component: StudentSettings,
              },
            ],
          },
        ],
      },
      {
        path: '/exams/:examId/',
        name: 'exam-edit-entry',
        component: ExamEditorView,
        meta: {
          isExamEditor: true,
          breadcrumbs: 'examEditor',
          overview: {
            scrollTop: 0,
          },
          qbank: {
            scrollTop: 0,
          },
        },
        children: [
          {
            path: 'questions',
            name: 'exam-edit-qbank',
            component: QuestionBank,
            meta: { isExamQuestionBank: true },
          },
          {
            path: 'question/:questionId',
            name: 'exam-edit-question',
            props: true,
            component: QuestionView,
            meta: { isQuestionView: true },
          },
          {
            path: '',
            name: 'exam-edit-review',
            component: ExamReview,
            children: [
              {
                path: '',
                name: 'exam-edit-overview',
                component: ExamOverviewTab,
                meta: { isExamOverviewTab: true },
              },
              {
                path: 'digital',
                name: 'exam-edit-digital',
                component: ExamDigitalTab,
              },
              {
                path: 'print',
                name: 'exam-edit-print',
                component: ExamPrintTab,
              },
              {
                path: 'settings',
                name: 'exam-edit-settings',
                component: ExamSettingsTab,
              },
              {
                path: 'summary',
                name: 'exam-edit-summary',
                component: ExamSummaryTable,
              },
              {
                path: 'attachments',
                name: 'exam-edit-attachments',
                component: ExamSummaryAttachments,
              },
            ],
          },
        ],
      },
    ],
    ...useRequiresTeacherAuthRule(),
  },
  {
    path: '/assessment/:assessmentId',
    name: 'assessment-edit',
    component: AssessmentEntryLayoutView,
    ...useRequiresTeacherAuthRule(),
    props: { mode: AssessmentMode.TEACHER },
  },
  {
    // Used for printing exams that are being edited
    // auth not required because exam is always read from print store only
    path: '/print/',
    name: 'exam-print-current',
    component: ExamPrintView,
  },
  {
    // Used for printing exams for screenshots
    path: '/print/:examId',
    name: 'exam-print',
    component: ExamPrintView,
    ...useRequiresTeacherAuthRule(),
  },
  {
    path: '/books/:bookId',
    name: 'book-mgmt',
    component: MainView,
    meta: { isBooksView: true },
    children: [
      {
        path: '',
        name: 'book-edit',
        component: BookEdit,
        meta: { breadcrumbs: 'bookEdit' },
      },
    ],
    ...useRequiresTeacherAuthRule(),
  },
  {
    path: '/signup/',
    name: 'signup',
    component: SignView,
    children: [
      {
        path: '',
        name: 'signup-entrypoint',
        component: SignEntrypoint,
        meta: { requiresCode: false },
      },
      {
        path: 'email',
        name: 'signup-email',
        component: SignupEmail,
        meta: { requiresCode: false },
      },
      {
        path: 'code',
        name: 'signup-code',
        component: SignupCode,
        meta: { requiresCode: false },
      },
      {
        path: 'verification/:code/:email',
        name: 'signup-long-code-verified',
        component: SignupCodeLong,
        meta: { requiresCode: false },
      },
      {
        path: 'code-invalid/:error',
        name: 'signup-code-invalid',
        component: SignupCodeInvalid,
        meta: { requiresCode: true },
      },
      {
        path: 'code-verified',
        name: 'signup-code-verified',
        component: SignupCodeVerified,
        meta: { requiresCode: true },
      },
      {
        path: 'suggested-schools',
        name: 'signup-suggested-schools',
        component: SignupSuggestedSchools,
        meta: { requiresCode: true },
      },
      {
        path: 'pick-school',
        name: 'signup-pick-school',
        component: SignupPickSchool,
        meta: { requiresCode: true },
      },
      {
        path: 'create-school',
        name: 'signup-create-school',
        component: SignupCreateSchool,
        meta: { requiresCode: true },
      },
      {
        path: 'details',
        name: 'signup-details',
        component: SignupPersonalDetails,
        meta: { requiresCode: true },
      },
      {
        path: 'student/:token/:code',
        name: 'signup-student',
        component: SignupStudent,
        meta: { requiresCode: false },
      },
      {
        path: 'not-available',
        name: 'signup-not-available',
        component: SignupSubjectNotAvailable,
        meta: { requiresCode: true },
      },
    ],
    ...useSignupCode(),
  },
  {
    path: '/auth/',
    name: 'unauthenticated',
    component: SignView,
    children: [
      {
        path: '',
        name: 'login-entrypoint',
        component: SignEntrypoint,
        ...useAlreadyHasAuthRule(),
        meta: { dropMasquerade: true },
      },
      {
        path: 'email',
        name: 'login-email',
        component: LoginEmail,
        ...useAlreadyHasAuthRule(),
      },
      {
        path: 'password-request',
        name: 'login-password-request',
        component: RequestPassword,
        ...useAlreadyHasAuthRule(),
      },
      {
        path: ':code/:email',
        name: 'login-password-change',
        component: ChangePassword,
        ...useAlreadyHasAuthRule(),
      },
      {
        path: 'code-invalid/:error',
        name: 'login-code-invalid',
        component: SignupCodeInvalid,
        props: { isLogin: true },
      },
      {
        path: 'not-verified',
        name: 'not-verified',
        component: NotVerified,
      },
      {
        path: 'logout',
        name: 'logout',
        component: Logout,
        meta: { dropMasquerade: true },
      },
      {
        path: 'test-entrypoint/:token/:validSeconds',
        name: 'test-entrypoint',
        component: TestEntrypoint,
      },
    ],
  },
  {
    path: '/sso/login/:type',
    component: SignView,
    children: [
      {
        path: '',
        component: SSOAuthReceiver,
        props: (route) => ({
          type: route.params.type,
          token: route.query.token,
        }),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    name: 'page-not-found',
    component: PageNotFound,
  },
]

const routes = import.meta.env.VITE_ROSLING_HOSTS.split(',').includes(
  window.location.host
)
  ? kmRoutes
  : defaultRoutes

const scrollBehavior = (to, from) => {
  // TODO: make sure that all that is needed
  const scrollpos = { top: 0, left: 0, behavior: 'smooth' }
  const fromQuestionToOverview =
    from.name === 'exam-edit-question' && to.name === 'exam-edit-overview'
  const fromQuestionToQbank =
    from.name === 'exam-edit-question' && to.name === 'exam-edit-qbank'

  if (fromQuestionToOverview) {
    scrollpos.top = to.meta.overview.scrollTop
  } else if (fromQuestionToQbank) {
    scrollpos.top = to.meta.qbank.scrollTop
  }

  return new Promise((resolve) => {
    if (fromQuestionToQbank) {
      // (tablet mode) wait for qbank to load
      setTimeout(() => {
        resolve(scrollpos)
      }, 500)
    } else if (fromQuestionToOverview) {
      setTimeout(() => {
        resolve(scrollpos)
      }, 750)
    } else if (from.fullPath !== to.fullPath) {
      // when changing to other routes -> scroll to top
      setTimeout(() => {
        // wait for qbank when going from overview
        scrollpos.behavior = 'auto'
        resolve(scrollpos)
      }, 0)
    }
  })
}

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior,
})

router.beforeEach((to, from) => {
  // TODO: make sure that all that is needed
  // This is to save the scroll position
  if (
    from.name === 'exam-edit-overview' &&
    to.name === 'exam-edit-question' &&
    from.meta?.overview?.scrollTop !== undefined
  ) {
    from.meta.overview.scrollTop = document.documentElement.scrollTop
  } else if (
    from.name === 'exam-edit-qbank' &&
    to.name === 'exam-edit-question' &&
    from.meta?.qbank?.scrollTop !== undefined
  ) {
    from.meta.qbank.scrollTop = document.documentElement.scrollTop
  }

  const title = to.meta.title
  if (title) {
    document.title = title
  }

  if (from.query.locale && !to.query.locale) {
    to.query.locale = from.query.locale
    return to
  }

  if (
    from.query.masquerade &&
    !to.meta.dropMasquerade &&
    !to.query.masquerade
  ) {
    to.query.masquerade = from.query.masquerade
    return to
  }
})

let appHasLoaded = false
async function onReload() {
  const tokenStore = useTokenStore()
  const userStore = useUserStore()
  const cacheStore = useSessionCacheStore()
  // token store must run first to ensure we are in the correct mode
  await tokenStore.onReload()
  try {
    if (tokenStore.hasValidTokenInCurrentMode()) {
      await userStore.onReload()
      await cacheStore.onReload()
    }
  } catch (e) {
    // don't prevent navigation if we fail here
    Bugsnag.notify(e)
  }
}

router.beforeResolve(async (_to, _from) => {
  // beforeResolve runs after beforeEach
  if (!appHasLoaded) {
    await onReload()
    appHasLoaded = true
  }
})

const detectNavigationFailure = (navigationResult) => {
  if (isNavigationFailure(navigationResult)) {
    throw new Error('Navigation prevented')
  }
}

export async function routerPush(name, params = {}, query = {}) {
  let navigationResult
  try {
    navigationResult = await router.push({
      name,
      params,
      query,
    })
  } catch {
    detectNavigationFailure(navigationResult)
  }
}

export async function routerPushPath(path, params = {}, query = {}) {
  let navigationResult
  try {
    navigationResult = await router.push({
      path,
      params,
      query,
    })
  } catch {
    detectNavigationFailure(navigationResult)
  }
}

export async function routerPushPathWithQuery(pathWithQueryParams) {
  const url = new URL(pathWithQueryParams, window.location.origin) // Use URL API to parse the redirect
  const path = url.pathname // Extract the path part ("/home")
  const query = Object.fromEntries(url.searchParams.entries()) // Convert search params to an object

  // Perform the router.push call
  let navigationResult
  try {
    navigationResult = await router.push({
      path,
      query,
    })
  } catch {
    detectNavigationFailure(navigationResult)
  }
}

export async function routerReplace(name, params = {}, query = {}) {
  let navigationResult
  try {
    navigationResult = await router.replace({ name, params, query })
  } catch {
    detectNavigationFailure(navigationResult)
  }
}

export async function routerPushQuery(query) {
  let navigationResult
  try {
    navigationResult = await router.push({ query })
  } catch {
    detectNavigationFailure(navigationResult)
  }
}

export async function routerReplaceQuery(query) {
  let navigationResult
  try {
    navigationResult = await router.replace({ query })
  } catch {
    detectNavigationFailure(navigationResult)
  }
}

export async function routerBack() {
  let navigationResult
  try {
    navigationResult = await router.go(-1)
  } catch {
    detectNavigationFailure(navigationResult)
  }
}

trackRouter(router) // track router for google analytics
