import { httpShared, throwOnAxiosError } from './http'

export async function courseGetById(courseId) {
  try {
    const res = await httpShared.get(`/v1/course/${courseId}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve course')
  }
}

export async function courseSettingsGetById(courseId) {
  try {
    const res = await httpShared.get(`/v1/courses/${courseId}/settings`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve course settings')
  }
}
