import { setGlobalLocale } from '@/i18n'
import { useFavicon } from '@vueuse/core'
import { useUserStore } from '@/stores/user'
import { type RouteLocationNormalized } from 'vue-router'

const KMMainView = () => import('./views/KMMainView.vue')
const KMTokenReceiverView = () => import('./views/TokenReceiver.vue')
const QuestionBankView = () => import('../views/QuestionBankView.vue')
const QuestionView = () => import('../views/question/QuestionView.vue')
const ExamEditorView = () => import('../views/ExamEditorView.vue')
const ExamReview = () => import('../views/exam/ExamReview.vue')
const ExamOverviewTab = () => import('../views/exam/ExamOverviewTab.vue')
const ExamDigitalTab = () => import('../views/exam/ExamDigitalTab.vue')
const ExamPrintTab = () => import('../views/exam/ExamPrintTab.vue')
const ExamSettingsTab = () => import('../views/exam/ExamSettingsTab.vue')
const ExamSummaryTable = () =>
  import('../views/exam/components/summary-table/ExamSummaryTable.vue')
const ExamSummaryAttachments = () =>
  import('../views/exam/components/ExamSummaryAttachments.vue')
const QuestionBank = () => import('../views/question-bank/QuestionBank.vue')
const ExamPrintView = () => import('../views/ExamPrintView.vue')
const PageNotFound = () => import('../views/PageNotFound.vue')

function kmAuth() {
  return {
    async beforeEnter(to: any) {
      const userStore = useUserStore()
      const kmMode = !!to.meta.km
      if (kmMode !== userStore.km) {
        userStore.setKMMode(kmMode)
        if (kmMode) {
          setGlobalLocale('sv')
          useFavicon('/favicon-km.ico')
        }
      }
    },
  }
}

export const kmRoutes = [
  {
    path: '/',
    name: 'km',
    component: KMMainView,
    props: { keepAlive: true },
    meta: { km: true },
    children: [
      {
        path: '',
        name: 'km-questions',
        component: QuestionBankView,
        meta: {
          breadcrumbs: 'questionBank',
          title: 'Uppgiftsbanken KM',
          isMainQuestionBank: true,
        },
      },
      {
        path: ':questionId',
        name: 'qbank-question',
        props: true,
        component: QuestionView,
        meta: { isQuestionView: true, title: 'Uppgiftsbanken KM' },
      },
    ],
    ...kmAuth(),
  },
  {
    path: '/exams/',
    name: 'km-exam-mgmt',
    component: KMMainView,
    props: { keepAlive: true },
    meta: { km: true },
    children: [
      {
        path: ':examId/',
        name: 'km-exam-edit-entry',
        component: ExamEditorView,
        meta: {
          isExamEditor: true,
          breadcrumbs: 'examEditor',
          title: 'Uppgiftsbanken KM',
          overview: {
            scrollTop: 0,
          },
          qbank: {
            scrollTop: 0,
          },
        },
        children: [
          {
            path: 'questions',
            name: 'exam-edit-qbank',
            component: QuestionBank,
            meta: { isExamQuestionBank: true },
          },
          {
            path: 'question/:questionId',
            name: 'exam-edit-question',
            props: true,
            component: QuestionView,
            meta: { isQuestionView: true },
          },
          {
            path: '',
            name: 'exam-edit-review',
            component: ExamReview,
            children: [
              {
                path: '',
                name: 'exam-edit-overview',
                component: ExamOverviewTab,
                meta: { isExamOverviewTab: true },
              },
              {
                path: 'digital',
                name: 'exam-edit-digital',
                component: ExamDigitalTab,
              },
              {
                path: 'print',
                name: 'exam-edit-print',
                component: ExamPrintTab,
              },
              {
                path: 'settings',
                name: 'exam-edit-settings',
                component: ExamSettingsTab,
              },
              {
                path: 'summary',
                name: 'exam-edit-summary',
                component: ExamSummaryTable,
              },
              {
                path: 'attachments',
                name: 'exam-edit-attachments',
                component: ExamSummaryAttachments,
              },
            ],
          },
        ],
      },
    ],
    ...kmAuth(),
  },
  {
    path: '/kmauth',
    name: 'kmauth',
    component: KMMainView,
    meta: { km: true },
    children: [
      {
        path: '',
        name: 'km-token',
        component: KMTokenReceiverView,
        props: (route: RouteLocationNormalized) => ({
          token: route.query.token,
        }),
        meta: { title: 'Uppgiftsbanken KM' },
      },
    ],
    ...kmAuth(),
  },
  {
    // Used for printing exams that are being edited
    // auth not required because exam is always read from print store only
    path: '/print/',
    name: 'exam-print-current',
    meta: { km: true },
    component: ExamPrintView,
    ...kmAuth(),
  },
  {
    // Used for printing exams by ID
    path: '/print/:examId',
    name: 'exam-print',
    meta: { km: true },
    component: ExamPrintView,
    ...kmAuth(),
  },
  {
    path: '/:pathMatch(.*)',
    name: 'page-not-found',
    component: PageNotFound,
    meta: { title: 'Uppgiftsbanken KM' },
    ...kmAuth(),
  },
]
